import Loading from '@/components/loading';
import { useTheme } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { useMemo, useState } from 'react';
import { useAsyncEffect } from 'rooks';
import { useGraphqlResult } from '../../../../../data/query/graphqlProvider';
import type { Order } from '../../../../../types/schema';

export default function StripePayment( { children, amount, tip, dollarTip, method, paymentGateway } ) {
	const theme = useTheme();
	
	const { gateway: _gateway, companyLocation, company } = useGraphqlResult<Order>();
	
	const gateway = paymentGateway || _gateway || companyLocation?.gateway || company.mainPayment;
	
	const [ secret, setSecret ] = useState();
	
	const isDark = theme.palette.mode === 'dark';
	
	const stripe = useMemo( () => {
		if ( gateway?.external !== 'STRIPE' ) return null;
		if ( !gateway?.externalId ) throw 'Card Reader could not be loaded.';
		return loadStripe( process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY!, {
			apiVersion   : '2020-08-27',
			stripeAccount: gateway.externalId,
		} );
	}, [ gateway ] );
	
	useAsyncEffect( async () => {
		const { data } = await axios.post( '/api/processor/payment/createIntent', {
			id: gateway.id,
			amount,
			tip,
			dollarTip,
			method,
		} );
		setSecret( data.secret );
	}, [] );
	
	if ( !secret ) return <Loading/>;
	
	return (
		<Elements
			stripe={stripe}
			options={{
				clientSecret: secret,
				appearance  : {
					theme    : isDark ? 'night' : 'stripe',
					variables: {
						colorBackground: theme.palette.background.default,
					},
					rules: {
						'.Tab': {
							border   : `1px solid ${theme.palette.divider}`,
							boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
						},
						'.Input': {
							border   : `1px solid ${theme.palette.divider}`,
							boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
						},
						'.Label': {
							fontWeight: '500',
						},
						'.PickerItem': {
							border: `1px solid ${theme.palette.divider}`,
						},
						'.PickerItem:hover': {
							backgroundColor: theme.palette.primary.main,
						},
					},
				},
			}}>
			{children}
		</Elements>
	);
}
