import CallEffect from '@/components/callEffect';
import Attachment from '@/components/fileUploading/attachment';
import Form from '@/components/form';
import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import FormAddress from '@/components/form/fields/address';
import FormSelect from '@/components/form/fields/select';
import FormTextField from '@/components/form/fields/textField';
import { useGraphqlResult } from '@/data/query/graphqlProvider';
import SignModal from '@/modals/sign';
import { getPaymentNote } from '@/pages/dashboard/commerce/payment/details';
import { makePayment } from '@/pages/dashboard/commerce/payment/helpers';
import { ClientInfo } from '@/pages/p/commerce/clientInfoForm';
import { surchargeFeeAtom } from '@/pages/settings/cards';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal, useModalControls } from '@/providers/modal';
import { AddressBase, ClientCredit, GatewayBase, Order } from '@/types/schema';
import postCloverMeteredBilling from '@/utils/api/postCloverMeteredBilling';
import {
	ArrowBackIos as ArrowBackIosIcon,
	Assignment as AssignmentIcon,
	Payment as PaymentIcon,
} from '@mui/icons-material';
import { Box, Button, MenuItem, Stack, Typography } from '@mui/material';
import axios from 'axios';
import type { FormikProps } from 'formik';
import { useAtomValue } from 'jotai/index';
import { isEmpty, round } from 'lodash-es';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

const validationSchema = ( gateExternal: string ) => Yup.object().shape( {
	bankRoutingNbr: Yup.string()
		.required( 'Routing number is required' )
		.length( 9, 'Routing number must be 9 digits' ),
	bankAccountNbr: Yup.string()
		.required( 'Account number is required' )
		.min( 4, 'Account number must be at least 4 digits' ),
	confirmBankAccountNbr: Yup.string()
		.required( 'Confirm account number is required' )
		.min( 4, 'Account number must be at least 4 digits' )
		.oneOf( [ Yup.ref( 'bankAccountNbr' ) ], 'Account numbers must match' ),
	bankAccountType: gateExternal === 'CARD_CONNECT'
		? Yup.string()
			.oneOf( [ 'ECHK', 'ESAV' ], 'Account type must be Checking or Savings' )
			.required( 'Account type is required' )
		: Yup.string(),
} );

type Props = {
	amount: number,
	cardFee?,
	tip?,
	dollarTip?,
	method,
	cancel,
	confirm: ( paymentObject: any ) => Promise<void>,
	locationPayment?: boolean,
	hideSignature?: boolean,
	client?: ClientInfo,
	storeOrder?: boolean,
	order?: Order,
	prepayClientId?: string,
	cardToken?: string,
	cardType?: string,
	paymentGateway: Pick<GatewayBase, 'id' | 'external'>,
	selectedCredits?: ClientCredit[],
	invoiceNumber?: string,
	invoiceId?: string
};

function Ach( {
	amount,
	cardFee = 0,
	tip,
	dollarTip,
	method,
	cancel,
	confirm,
	locationPayment,
	hideSignature,
	client,
	storeOrder,
	order,
	prepayClientId,
	cardToken,
	cardType,
	paymentGateway,
	selectedCredits,
	invoiceNumber,
	invoiceId,
}: Props ) {
	const router = useRouter();
	const { t } = useTranslation();
	const { showModal } = useModal();
	const { enqueueSnackbar } = useSnackbar();
	const { closeModal } = useModalControls();
	const { staff } = useUserInfo();
	const {
		      id,
		      company,
		      client: orderClient,
		      paidTotal,
		      metadata,
		      staff: orderStaff,
		      companyLocation,
	      } = useGraphqlResult<Order>();
	const [ signature, setSignature ] = useState<string | undefined>( undefined );
	const [ clientAddress, setClientAddress ] = useState( orderClient?.addresses?.[ 0 ] );
	const surchargeFeePercent = useAtomValue( surchargeFeeAtom );
	const addressForm = useRef<FormikProps<{}>>( null );
	const [ isAddressFormValid, setIsAddressFormValid ] = useState( false );
	
	const route = router.route.split( '/' )[ 1 ];
	const isClientPage = route === 'p' || route === 'client';
	const gateway = paymentGateway;
	
	const paymentType = method.toUpperCase();
	const payByTender = ![ 'CARD',
	                       'ACH', 'INVOICE CREDIT' ].includes( paymentType ) && !paymentType?.includes( 'SAVED' );
	
	const surchargePercent = ( method === 'card' || method?.includes( 'saved' ) ) && cardType !== 'debit'
		? surchargeFeePercent
		: 0;
	const paymentCardFee = cardType === 'debit' || ( surchargePercent || 0 ) > 0 ? 0 : cardFee;
	const cashDiscount = paidTotal === 0 && metadata?.enableCashDiscount && ( company.metadata?.cashDiscount || 0 ) / 100;
	
	return (
		<Stack spacing={2}>
			<Form
				noClose
				initialValues={{
					bankRoutingNbr       : '',
					bankAccountNbr       : '',
					confirmBankAccountNbr: '',
					bankAccountType      : '',
					payerFirstName       : orderClient?.contact?.split( ' ' )?.[ 0 ] || '',
					payerLastName        : orderClient?.contact?.split( ' ' )?.[ 1 ] || '',
				}}
				validationSchema={validationSchema( gateway.external )}
				onSubmit={async ( values ) => {
					await addressForm.current?.submitForm();
					if ( !isEmpty( await addressForm.current?.validateForm() ) ) return;
					try {
						// pay by tenders, saved card, selected client payments, and card connect ach
						if ( isClientPage && payByTender ) throw new Error( 'Invalid payment method.' );
						
						enqueueSnackbar( t( 'commerce:payment-is-processing' ), { variant: 'info' } );
						let cloverOrder;
						if ( cashDiscount > 0 ) {
							const { data: commerce } = await axios.post( '/api/tempCashDiscount', {
								id: id,
								storeOrder,
							} );
							if ( commerce.commerce && commerce?.data ) {
								cloverOrder = commerce.commerce;
							}
						}
						
						const paymentData = await makePayment( {
							type  : method.toUpperCase(),
							amount: amount,
							fee   : paymentCardFee,
							tip   : dollarTip || amount * tip / 100,
							note  : getPaymentNote( {
								invoiceNote: prepayClientId ? 'PrePaid' : invoiceNumber || '',
								method,
								locationPayment,
							} ),
							signature      : signature || null,
							orderId        : invoiceId || cloverOrder?.id || id,
							gatewayId      : gateway?.id,
							companyId      : company.id,
							metadata       : company?.metadata,
							staffExternalId: staff?.externalId || orderStaff?.externalId || null,
							staffId        : staff?.id,
							payerId        : staff?.id || orderClient?.id,
							payerName      : orderClient?.name || orderClient?.email || orderClient?.contact || ( staff?.user?.firstName || '' + staff?.user?.lastName ),
							cardToken      : cardToken || ( method?.includes( 'saved' )
								? method.split( '-' )?.[ 1 ]
								: undefined ),
							bankRoutingNbr : values.bankRoutingNbr,
							bankAccountNbr : values.bankAccountNbr,
							bankAccountType: values.bankAccountType,
							payerFirstName : values.payerFirstName,
							payerLastName  : values.payerLastName,
							isClientPage   : isClientPage,
							clientAddress  : clientAddress,
							invoiceNumber  : invoiceNumber,
						} );
						
						if ( paymentData ) {
							if ( companyLocation?.gateway?.external === 'CLOVER' && method === 'ach' ) {
								await postCloverMeteredBilling( {
									orderId  : id,
									gatewayId: companyLocation.gateway?.id,
									eventType: 'Bank Account',
									key      : 'bankAccount',
									staffId  : staff?.id,
									clientId : orderClient?.id,
								} ).catch( () => [] );
							}
							await confirm( {
								payment    : paymentData?.payment,
								method,
								credits    : selectedCredits,
								syncedOrder: cloverOrder || order,
							} );
						}
						closeModal();
					} catch ( e ) {
						const err = e?.response?.data?.cloverErrors || e?.cloverErrors;
						if ( err ) {
							throw typeof err === 'string'
								? err
								: err?.error?.message || err?.message || 'An error has occurred. Clover.com';
						} else {
							enqueueSnackbar( t( 'commerce:something-went-wrong' ), { variant: 'default' } );
						}
					}
				}}>
				{( formik ) => (
					<React.Fragment>
						<Stack spacing={1}>
							<FormTextField eager fullWidth name='bankRoutingNbr' label={t( 'commerce:bank-routing-number' )}/>
							<FormTextField fullWidth name='bankAccountNbr' label={t( 'commerce:bank-account-number' )}/>
							<FormTextField
								eager
								fullWidth
								name='confirmBankAccountNbr'
								label={t( 'commerce:confirm-bank-account-number' )}
							/>
							{gateway.external === 'CARD_CONNECT' && (
								<FormSelect
									fullWidth
									name='bankAccountType'
									label={t( 'commerce:account-type' )}>
									<MenuItem value='ECHK'>Checking</MenuItem>
									<MenuItem value='ESAV'>Savings</MenuItem>
								</FormSelect>
							)}
						</Stack>
						{signature && (
							<Box sx={{ mt: 2 }}>
								<Attachment
									removeDownload
									src={signature}
									imageSX={{ width: '100%', height: 'unset', objectFit: 'cover' }}
								/>
							</Box>
						)}
						<Stack spacing={1} direction='row' alignItems='center'>
							<AsyncLoadingButton startIcon={<ArrowBackIosIcon/>} variant='outlined' onClick={cancel}>
								{t( 'common:back' )}
							</AsyncLoadingButton>
							{!hideSignature && !client && (
								<Button
									variant='outlined'
									color='primary'
									startIcon={<AssignmentIcon/>}
									onClick={() => showModal( SignModal, { fullPageBottomSheet: false }, {
										onSave   : setSignature,
										invoiceId: id,
									} )}>
									{t( 'commerce:add-signature' )}
								</Button>
							)}
							<AsyncLoadingButton
								variant='contained'
								color='primary'
								disabled={!formik.isValid}
								startIcon={<PaymentIcon/>}
								sx={{ width: 150 }}
								onClick={async () => formik.submitForm()}>
								{t( 'commerce:finish' )}
							</AsyncLoadingButton>
						</Stack>
					</React.Fragment>
				)}
			</Form>
		</Stack>
	);
}

export default function Wrapper( props: Props ) {
	const { external } = props.paymentGateway;
	if ( ![ 'CARD_CONNECT' ].includes( external ) ) return <Typography>Invalid Gateway</Typography>;
	return <Ach {...props}/>;
}
